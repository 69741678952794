import React,{Component} from "react";
import { NavLink } from 'react-router-dom';
import Currency from "../components/component.currency";
import Language from "../components/component.language";
import moment from "moment";
import Global from '../globals/global';
import FooterDelMar from "../partials/partial.footer.delmar";
//if (typeof window === "undefined")  var window={}
const location = window.location;
var activestyle={
    fontWeight: "bold",
    color: "orange"
};


export default class footer extends Global {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount(){
           this.setmenuoptions();
           //this.forceUpdate()
    }

    render() {

         var disablepages = this.config().nofooterpages || ['travelagentmessage','mitmessage','bancomermessage'];


         const disable = disablepages.find(page => {
                return location.pathname.search(page) != -1;
         })
         if(disable) return null;
         

         if(this.config().brand == 'delmarboutiqueaccommodation'){
            return <FooterDelMar />
         }

        return (


            <footer className="revealed">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">

                        {this.config().brand == 'trafictours' || this.config().brand == 'totoura' || this.config().brand == 'sunexcursions' || this.config().brand == 'transat' || this.config().brand == 'turissimojamaica' || this.config().brand == 'turissimo'?
                            <h3>{this.t("Need help?")}</h3>
                        : <h3>{this.t("Need help with experiences?")}</h3>}
                            
                            {this.config().phone != ''?
                            <a href={"tel:"+this.config().phone} id="phone" className="price_ph">+{this.config().phone} {/*this.t('from')*/} {this.t('USA')}</a>
                            :''
                            }
                            
                             {this.config().mexicophone?<a href={"tel:"+this.config().mexicophone} id="phone">+{this.config().mexicophone} {/*this.t('from')*/} {'MEX'}</a>:null}

                            <a href={"mailto:"+this.config().email} id="email_footer">{this.config().email}</a>
                        </div>
                        <div className="col-md-3">
                            <h3>{this.t("About")}</h3>
                            <ul>
                                <li><NavLink to="/aboutus" activeStyle={activestyle} >{this.t("About us")}</NavLink></li>
                                <li><NavLink to="/privacy" activeStyle={activestyle} >{this.t("Privacy Policy")}</NavLink></li>
                                {this.config().brand == 'trafictours' || this.config().brand == 'totoura' || this.config().brand == 'sunexcursions' || this.config().brand == 'transat' || this.config().brand == 'turissimojamaica' || this.config().brand == 'turissimo' || this.config().brand == 'toorzy' ?
                                <li><NavLink to="/cookies" activeStyle={activestyle} >{this.t("Cookies Policy")}</NavLink></li> 
                                :''}
                                
                                {this.config().myaccount?<li><NavLink to="/myaccount" activeStyle={activestyle} >{this.t("Account")}</NavLink></li>:''}
                                <li><NavLink to="/terms" activeStyle={activestyle} >{this.t("Terms and condition")}</NavLink></li>
                            </ul>
                        </div>
                        <div className="col-md-3">
                            <h3>{this.t("Discover")}</h3>
                            <ul>
                                {this.config().transfers?<li><NavLink to="/transfers" activeStyle={activestyle} >{this.t("Transfers")}</NavLink></li>:''}
                                {this.config().tours?<li><NavLink to="/tours/1" activeStyle={activestyle} >{this.t("Tours")}</NavLink></li>:''}
                                {this.config().wishlist?<li><NavLink to="/wishlist" activeStyle={activestyle} >{this.t("Wishlist")}</NavLink></li>:''}
                                {this.config().contact?<li><NavLink to="/contact" activeStyle={activestyle} >{this.t("Contact")}</NavLink></li>:''}
                            </ul>
                        </div>
                        <div className="col-md-2">
                            <h3>{this.t("Settings")}</h3>
                            <div className="styled-select">
                                <Language header={false} />
                            </div>
                            <div className="styled-select">
                                <Currency header={false} />
                            </div>
                        </div>
                    </div>{/* -- End row --*/}
                    <div className="row">
                        <div className="col-md-12">
                            <div id="social_footer">
                               {/*  <ul>
                                    <li><a href="#"><i className="icon-facebook"></i></a></li>
                                    <li><a href="#"><i className="icon-twitter"></i></a></li>
                                </ul>
                                */}
                                
                                 <p>{this.config().brandname} Experiences | <a href="https://mobilesail.com/" target="_blank" class="footer_pow">Powered by Mobile Sail</a></p>

                            </div>
                        </div>
                    </div>{/* -- End row  --*/}
                </div>{/* -- End container  --*/}

                

            </footer>

        );
    }
}

