import React, { Component } from 'react';
import { NavLink,withRouter } from "react-router-dom";
import Global from '../globals/global';
import DatePicker from "react-datepicker";
import moment from "moment";
const $ = window.$;

class Cicles extends Global{

    constructor(props) {
    super(props);

    this.state ={
       date: new Date()
      }

  }

    componentDidMount(){

      

    }

    changedate(date){
        this.state.date= date;
        this.forceUpdate();
        console.log('fecha cambiada')
    }

    setcicles(){

    }

    setdayclass(date){
        let birtday ="2019-10-21";
        let classname = ''
        let totalcicledays = 52

        if(date > moment(birtday)) {

            if(date <= moment(birtday).add(totalcicledays,'days')){
              classname='bg-warning'
            }

            if(date > moment(birtday).add(totalcicledays,'days') && date <= moment(birtday).add(totalcicledays*2,'days')){
              classname='bg-success'
            }

            if(date > moment(birtday).add(totalcicledays*2,'days') && date <= moment(birtday).add(totalcicledays*3,'days')){
              classname='bg-warning'
            }

            if(date > moment(birtday).add(totalcicledays*3,'days') && date <= moment(birtday).add(totalcicledays*4,'days')){
              classname='bg-success'
            }

            if(date > moment(birtday).add(totalcicledays*4,'days') && date <= moment(birtday).add(totalcicledays*5,'days')){
              classname='bg-warning'
            }

            if(date > moment(birtday).add(totalcicledays*5,'days') && date <= moment(birtday).add(totalcicledays*6,'days')){
              classname='bg-success'
            }

            if(date > moment(birtday).add(totalcicledays*6,'days') && date <= moment(birtday).add(totalcicledays*7,'days')){
              classname='bg-warning'
            }


       }

       return classname;

       
    }


    render() {

       

        return (
            <section className="decline_b">
                <div className="intro_title error">
                    <h4 className="animated fadeInDown">Ciclos</h4>
                    <p className="animated fadeInDown">Fechas</p>
                    

                    <DatePicker
                    selected={this.state.date}
                    withPortal={true}
                    dateFormat="yyyy-MM-dd"
                    className="form-control"
                    placeholderText='fecha'
                   // filterDate={this.setcicles.bind(this)}
                    onChange={this.changedate.bind(this)}
                    disabled={false}
                    dayClassName={this.setdayclass.bind(this)}
                  />

                </div>
            </section>
        )
    }

}

export default Cicles