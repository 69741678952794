import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Global from '../globals/global';
import ReactResizeDetector from 'react-resize-detector';


import BancomerEndPoint from '../pages/page.end.point.bancomer';
import MitEndPoint from '../pages/page.end.point.mit';

import TravelAgentMessage from '../pages/page.travel.agent.message';
import HomeLanding from '../pages/page.homelanding';

import Home from "../pages/page.home";
import Tours from "../pages/page.tours";
import ToursAvailability from "../pages/page.tours.availability";
import Cart from "../pages/page.cart";
import Contact from "../pages/page.contact";
import Error404 from "../pages/page.404";
import Header from "../partials/partial.header";
import Footer from "../partials/partial.footer";

import Transfers from '../pages/page.transfers';

import Checkout from "../pages/page.checkout";

import TransferPrices from '../pages/page.transfers.prices';
import PurchaseHistoryDetail from '../pages/page.purchase.history.detail';
import PurchaseHistoryDetailCart from '../pages/page.purchase.history.detail.cart';
import Specials from '../pages/page.specials';
import TrendingReviews from '../pages/page.trending.reviews';
import AboutUs from '../pages/page.about.us';
import Privacy from '../pages/page.privacy';
import TourDetails from '../pages/page.tour.details';
import TermsConditions from '../pages/page.terms';

import WishList from '../pages/page.wishlist';
import Dining from '../pages/page.dining';
import Shopping from '../pages/page.shopping';

import Login from '../pages/page.login';

import GroupPage from '../pages/page.group';
import LandingPage from '../pages/page.landing';

import MyAccount from '../pages/page.my.account';
import ShopDetails from '../pages/page.shop.details';
import Search from '../components/component.search';

import Cookies from '../pages/page.cookies';

import Cicles from '../pages/page.cicles';

import CartHeader from "../components/component.cart.header";

import Payment from '../pages/page.payment';

class Routes extends Global {

  redirect(Page_) {
    return (<Page_ />); //Cookies.get('loggedin') == 'true'? (<Page_ />) : (<Error /> );  // <Redirect to="/dashboard"/>
  }

  constructor(props) {
    super(props);

    this.state ={
        tranlation:false
      }

  }

  componentWillMount(){
    
    this.initialization(); //set default language, currency, destination, location
    
  }

  
  componentDidMount() {
    this.initializationdidmount();
  }


  loginredirect(Page_) {
    return this.getglobaldata('user') ? (<Page_ />) : (<Redirect to='/login' />);
  }

  homeredirect(Page_) {
    return !this.getglobaldata('user') ? (<Page_ />) : (<Redirect to='/' />);
  }


  toursredirect(Page_){

    if(this.config().brand == 'autopartner'){
      if(this.config().groupinfo)  return  (<Redirect to={'/group/'+this.config().groupinfo.UrlName} /> ); 
      if(this.config().landinginfo)  return  (<Redirect to={'/landing/'+this.config().landinginfo.UrlName} /> ); 
    }

   
    return  (<Redirect to='/tours/1' /> );  
    
  }


 


  render() {


    if (!this.state.tranlation) return null;

     let Home_ = this.config().enablehome2?HomeLanding:Home;

     var urlReferer = `${window.location.host}${window.location.pathname}`

    return (
      <div>

        
        {this.config().header?<Header />:''}
        

       

        <Switch>
         
          <Route path="/" exact component={Home_} />
         
          <Route path="/home" exact component={Home_} />
          


          <Route path="/search/transfers/:destinationid/:hotelid" component={Transfers} />
          <Route path="/transfers/prices/:id_transfer/:id_transfer_prices/:id_airport_arrival/:id_airport_departure/:type/:itemindex" component={TransferPrices} />
          <Route path="/transfers/prices/:id_transfer/:id_transfer_prices/:id_airport_arrival/:id_airport_departure/:type" component={TransferPrices} />

          <Route path="/transfers" component={Transfers} />

          <Route path="/tours/availability/:tourid/:action/:itemindex" component={ToursAvailability} />
          <Route path="/tours/availability/:tourid/:action" component={ToursAvailability} />
          <Route path="/cart" component={Cart} />
          <Route path="/checkout" component={Checkout} />

          <Route path="/search/tours/:destinationid/:tourname" component={Tours} />
          
          {/********************************************* */}
          <Route path="/tours/:destination/:destinationid/:locationid/:category/:page" component={Tours} />
          <Route path="/tours/:destination/:destinationid/:category/:page" component={Tours} />
          {/********************************************* */}
          
          <Route path="/tours/:destination/:destinationid/:locationid/:page" component={Tours} />
          <Route path="/tours/:destination/:destinationid/:page" component={Tours} />
          <Route path="/tours/:destination/:destinationid" component={Tours} />
          <Route path="/tours/:page" render={() => (this.toursdestinationredirect())} />

          <Route path="/tourdetails/:tourname/:tourid" component={TourDetails} />
          <Route path="/shopdetails/:shopid" component={ShopDetails} />

          <Route path="/tours" component={Tours} />

          <Route path="/trendingrev" component={TrendingReviews} />

          <Route path="/specials" component={Specials} />

          <Route path="/contact" component={Contact} />

          <Route path="/payment/:bank/:Id_APIPayment" component={Payment} />

          <Route path="/aboutus" component={AboutUs} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/terms" component={TermsConditions} />

          <Route path="/cookies" component={Cookies} />

          <Route path="/wishlist" component={WishList} />
          <Route path="/dining" component={Dining} />
          <Route path="/shopping" component={Shopping} />
          <Route path="/login" render={() => (this.homeredirect(Login))} />
          <Route path="/myaccount/:payment" component={MyAccount} />
          <Route path="/myaccount" render={() => (this.loginredirect(MyAccount))} />
          <Route path="/purchase/detail/cart/payment" component={PurchaseHistoryDetailCart} />
          <Route path="/purchase/detail/:idtransaction" component={PurchaseHistoryDetail} />
          <Route path="/mitmessage/:result" component={MitEndPoint} />
          <Route path="/bancomermessage/:result" component={BancomerEndPoint} />

         

          
          
          <Route component={Error404} />
        </Switch>
        <div id="search_p" className="zoom-anim-dialog mfp-hide">
          <Search />
        </div>
        
       
         {this.config().footer?<Footer />:''}
       
         {this.config().brand == 'turissimojamaica' || this.config().brand == 'turissimo' || this.config().brand == 'totoura'  || this.config().brand == 'sunexcursions' || this.config().brand == 'trafictours' && urlReferer.search('checkout') == -1?
                <a href={`https://api.whatsapp.com/send?phone=523222285601&text=${urlReferer}`} className="whats_icon" target="_blank">
                    <img src={this.image("img/whats_i.png")} />
                </a>
                :''}


        
      </div>
    );
  }
};

export default withRouter(Routes)

